import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/LifeSkill2023/emotionalregulation/1.jpg';
// import p2 from 'assests/Photos/LifeSkill2023/emotionalregulation/2.jpg';
// import p3 from 'assests/Photos/LifeSkill2023/emotionalregulation/3.jpg';
// import p4 from 'assests/Photos/LifeSkill2023/emotionalregulation/4.jpg';
// import p5 from 'assests/Photos/LifeSkill2023/emotionalregulation/5.jpg';
// import p6 from 'assests/Photos/LifeSkill2023/emotionalregulation/6.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from 'views/LifeSkill2023/Sidebar2023';

const EmotionalRegulation2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/emotionalregulation/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/emotionalregulation/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/emotionalregulation/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/emotionalregulation/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/emotionalregulation/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/emotionalregulation/6.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
        },
        {
          src: p6,
          source: p6,
          rows: 1,
          cols: 1,
        },
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      EMOTIONAL REGULATION
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 9 and 11	  &  Date: 05 OCTOBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        A Guest Lecture on the topic ‘Emotional Regulation’ was organised for the students of Classes 9 and 11 on Thursday, 5 October , 2023. The session was conducted by Dr Ruchi Gupta a practising psychiatrist in Bangalore with two decades of experience in the field of Mental Health and wellbeing. 
        <br></br>
        Emotional Regulation is a very pertinent issue among adolescents. The session was started with an insight into various distractions in today’s fast moving world like social media, peer pressure, gaming etc. 
        <br></br>
        The speaker highlighted the various techniques to control stress levels, emotions and work-life balance. The learning outcome was that, there is a need for adolescents to regulate their emotions in response to stressors. Failure to do so may confer risk for mental health.
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Climate change is the Everest of all problems, the thorniest challenge facing humankind - Lewis Gordon Pugh”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default EmotionalRegulation2023;